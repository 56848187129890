import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Sidebar from "./sidebar"
import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <div>
      <Header siteTitle={data.site.siteMetadata.title} siteDesc={data.site.siteMetadata.description} />

      <section className="section main-section">
        <div className="container">
          <div className="columns">
            <div className="column is-9">
          <p>&nbsp;</p>
          <div>{children}</div>
          </div>
          {/* <Sidebar>
            <RecentPosts />
          </Sidebar> */}
          <div className="column is-3 is-hidden-touch">
            <Sidebar />
          </div>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="container">
          {/* <Socials className="social-icons">
          <a href="https://twitter.com/crocdomains">
              <img src={twtrIcon} alt="twitter icon" />
            </a>
            <a href="https://instagram.com/crocdesigns">
              <img src={igIcon} alt="instagram icon" />
            </a>
          </Socials> */}
          <p>
            © {new Date().getFullYear()} wywywywy.com
          </p>
          <p>
            Contact me at <a href="mailto:wywywywy@gmail.com">wywywywy@gmail.com</a>
          </p>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
