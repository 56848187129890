import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressCategory(filter: {count: {gt: 0}}, sort: {order: ASC, fields: name}) {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `);

  const [active, setActive] = useState(false);

  const toggleNavMenu = () => {
    setActive(!active);
  }

  return (
    <nav className="navbar is-black is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <span className="title">
              {siteTitle}
            </span>
          </a>
          <div role="button" className={`navbar-burger burger ${active ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="mainNavMenu" onClick={toggleNavMenu}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>
        <div id="mainNavMenu" className={`navbar-menu ${active ? 'is-active' : ''}`}>
          <div className="navbar-end">
            {
              data.allWordpressCategory.edges.map(edge => (
                <Link className="navbar-item is-hidden-desktop is-size-5" to={`/category/${edge.node.slug}`} key={edge.node.slug}>
                  {edge.node.name}
                </Link>
              ))
            }
          </div>
        </div>
      </div>
    </nav>
  )
};

export default Header
