import React from 'react'
import { Link } from 'gatsby'
import * as moment from 'moment'

const Excerpt = ({ post }) => (
  <div className="box">
    <div className="columns is-centered has-shadow is-variable is-8">
      <div className="column is-4">
        <figure className="image">
          {
            post.content.match(/<img.*src="(.*?)".*?>/i) ?
              post.content.match(/<img.*src="(.*?)".*?>/i).map((str, idx) => {
                let imgLink = str.replace('http://', 'https://').replace('wyblog/', '');
                return idx === 1 ? (
                  <Link to={`/post/${post.slug}`} key={post.slug}>
                    <img src={imgLink} alt={imgLink} loading="lazy" className="img-has-shadow" />
                  </Link>
                ) : ''
              }) : ''
          }
        </figure>
      </div>
      <div className="column is-8">
        <div className="title">
          <Link to={`/post/${post.slug}`} className="has-text-primary" key={post.slug}>
            {post.title}
          </Link>
        </div>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
        </div>
        <p>
          <Link to={`/post/${post.slug}`} className="has-text-primary" key={post.slug}>
            READ MORE
          </Link>
        </p>
        <div className="has-text-right has-text-grey is-size-6">
          Published {moment(post.date).format('Do MMM YYYY')}
        </div>
      </div>
    </div>
  </div>
)

export default Excerpt