import React from 'react'
import { Link, useStaticQuery } from "gatsby"

const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressCategory(filter: {count: {gt: 0}}, sort: {order: ASC, fields: name}) {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `);

  return (
    <div className="sidebar">
      <p className="sidebar-header">
        CATEGORIES
      </p>
      {
        data.allWordpressCategory.edges.map(edge => (
          <p>
            <Link className="" to={`/category/${edge.node.slug}`} key={edge.node.slug}>
              {edge.node.name}
            </Link>
          </p>
        ))
      }
    </div>
  )
}

export default Sidebar